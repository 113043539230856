.container {
  overflow-x: hidden;
}

.list-group {
  max-width: 1000px;
}

.list-group-item {
  cursor: pointer;
  width: 140px;
  height: 40px;
}

.list-group-item-action:hover,
.list-group .active:hover {
  background-color: #ffda00;
  color: black;
}

.list-group .rounded {
    border-color: #ffda00;
}

.list-group .active {
    background-color: rgb(119, 113, 113);
  }

.video-player-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

/* Episodes List */

.episode-buttons-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 15px;
}
