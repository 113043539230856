
.movie-card {
  max-width: 1024px;
  display: flex;
  overflow: hidden;
  border-radius: 10px;
  background: rgb(119, 113, 113);
  box-shadow: 5px 5px 10px #ffda00;
  margin: auto;
  flex-direction: column;
  /* position: relative; */
  margin-top: 1rem !important;
}

.movie-card-thumbnail {
  position: relative;
  width: 100%;
  max-height: 576px;
  /* overflow: hidden; */
}

.movie-card-thumbnail img {
  position: relative;
  height: 100%;
  width: 100%;
  /* border-radius: 10px; */
  object-fit: cover;
  object-position: center;
}

.movie-card-body {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 31px;
}

.movie-card-title {
  text-transform: uppercase;
	text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff, 3px 3px 4px #ffda00;
	letter-spacing: 3px;	
	color: rgb(88,0,0);
	font-size: 31px;
	/* margin-bottom: 5px; */
  margin-top: 10px;
  width: 60%;
}

.movie-card-text {
  margin-left: 10px;
  color: rgba(227, 225, 225, 0.9);
	font-size: 18px;
}

.movie-tags {
	display: flex;
	align-items: center;
	column-gap: 17px;
  word-spacing: -4px;
}

.movie-tags-link {
	text-decoration: dotted;
	text-decoration-line: underline;
	text-decoration-color: rgba(255, 218, 0, 0.7);
}

/* --------------------------------------- Desktop ---------------------------------------------------- */

.desktop-mp.d-md-block .movie-card-title {
  font-size: 25px;
  width: 75%;
}

/*  */

.desktop-mp.d-sm-block .movie-card-title {
  font-size: 20px;
  width: 75%;
  margin-left: -25px;
}

.desktop-mp.d-sm-block .movie-tags {
  margin-left: -25px;
}

.desktop-mp.d-sm-block .rating-stars {
  margin-left: -30px;
}

/*  */

.desktop-mp.d-sm-none .movie-card-title {
  font-size: 15px;
  width: 75%;
  margin-left: -25px;
}

.desktop-mp.d-sm-none .movie-card-text {
  font-size: 13px;
}

.desktop-mp.d-sm-none .movie-tags {
  margin-left: -25px;
}

.desktop-mp.d-sm-none .rating-stars {
  margin-left: -30px;
}
/* --------------------------------------- Tablet ---------------------------------------------------- */

.tablet-mp.d-lg-block .movie-card {
  width: 800px;
}

.tablet-mp.d-md-block .movie-card {
  width: 640px; /* width: 700px; */
}

/* --------------------------------------- Mobile ---------------------------------------------------- */

/*  */
.mobile-mp.d-md-block .movie-card {
  width: 480px;  
}

.mobile-mp.d-md-block .movie-card-title {
  width: 80%;
  margin-left: -25px;
  font-size: 21px;
}

.mobile-mp.d-md-block .movie-tags {
  margin-left: -25px;
}

.mobile-mp.d-md-block .rating-stars {
  margin-left: -30px;
}

/*  */
.mobile-mp.d-sm-block .movie-card {
  width: 480px;
}

.mobile-mp.d-sm-block .movie-card-title {
  width: 80%;
  font-size: 20px;
  margin-left: -20px;
}

.mobile-mp.d-sm-block .movie-tags {
  margin-left: -20px;
}

.mobile-mp.d-sm-block .rating-stars {
  margin-left: -25px;
}

/*  */

.mobile-mp.d-sm-none .movie-card {
  width: 340px;
}

.mobile-mp.d-sm-none .movie-card-title {
  width: 80%;
  font-size: 16px;
  margin-left: -30px;
}

.mobile-mp.d-sm-none .movie-card-text {
  font-size: 13px;
}

.mobile-mp.d-sm-none .movie-tags {
  margin-left: -30px;
}

.mobile-mp.d-sm-none .rating-stars {
  margin-left: -30px;
}