.thumbnail-container {
    width: 900px;
    background-color: white;
    display: flex; 
    align-items: center; 
    justify-content: center; 
}

.thumbnail {
    position: relative;
    width: 350px;
}

.thumbnail img {
    width: 100%;
    display: block;
    margin-left: 60px;
}

.play-button {
    position: absolute;
    top: 58%;
    left: 15%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background: no-repeat center/68px 48px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
    cursor: pointer;
    z-index: 1;
    filter: grayscale(70%);
    transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
    border: 0;
}

.video-player {
    width: 100%;
    height: 100%;
}

/* Desktop */

.desktop-mp-vp-d-lg-block .thumbnail-container {
    width: 800px;
}

.desktop-mp-vp-d-md-block .thumbnail-container {
    width: 600px;
}

.desktop-mp-vp-d-sm-block .thumbnail-container {
    width: 450px;
    height: 230px;
    overflow: hidden;
}

.desktop-mp-vp-d-sm-none .thumbnail-container {
    width: 360px;
    height: 180px;
    overflow: hidden;
}

.desktop-mp-vp-d-sm-none .thumbnail {
    width: 280px;
}

/* Tablet */

.tablet-mp-vp-landscape .thumbnail-container {
    width: 700px;
}

.tablet-mp-vp .thumbnail-container {
    width: 550px;
}


/* Mobile */

.mobile-mp-vp-d-md-block .thumbnail-container {
    width: 400px;
    height: 200px;
    overflow: hidden;
}

.mobile-mp-vp-d-md-block .thumbnail {
    width: 280px;
}

.mobile-mp-vp-d-sm-block .thumbnail-container {
    width: 400px;
    height: 200px;
    overflow: hidden;
}

.mobile-mp-vp-d-sm-block .thumbnail {
    width: 280px;
}

.mobile-mp-vp .thumbnail-container {
    width: 300px;
    height: 170px;
    overflow: hidden;
}

.mobile-mp-vp .thumbnail {
    width: 220px;
}