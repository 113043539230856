.alphabetic-pagination {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;

  }
  
  .alphabetic-pagination-button {
    /* margin: 0.5px; */
    padding: 7px;
    background-color: rgb(119, 113, 113);
    color: #ffda00;
    cursor: pointer;
    border-color: black;
  }
  
  .alphabetic-pagination-button.active {
    background-color: #ffda00;
    color: black;
  }
  
  .alphabetic-pagination-button:hover {
    background-color: rgb(88, 0, 0);
    color: #ffda00;
  }
  