.actor.card .card-title {
    color: rgb(255, 255, 255);
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Desktop */
.actor.desktop.d-xl-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.desktop.d-lg-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.desktop.d-md-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.desktop.d-sm-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.desktop.d-sm-none .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Tablet */

.actor.tablet.d-md-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.tablet.d-lg-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Mobile */
.actor.mobile.d-md-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.mobile.d-sm-block .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.mobile.d-sm-none .card .card-title {
    color: white;
    text-align: center;
    /* font-family: "Montserrat", sans-serif; */
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Search */

/* .search.actor.desktop.d-xl-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

.search.actor.desktop.d-lg-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.desktop.d-md-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.search.actor.desktop.d-sm-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.search.actor.desktop.d-sm-none .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}

.search.actor.tablet.d-md-block .card .card-title {
    color: red;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.search.actor.tablet.d-lg-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Mobile 
.actor.mobile.d-md-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.mobile.d-sm-block .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
}

.actor.mobile.d-sm-none .card .card-title {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
} */

.actor.card {
     background-color: rgb(88, 0, 0);
}
.actor.card:hover {
    background-color: #ffda00;
}

.actor.card:hover .card-title {
    color: black;
}
