.slider-container {
  display: flex;
  align-items: center;
}

.slider-navigation {
    display: flex;
    align-items: center;
}

.slider-navigation-left {
    margin-right: 10px;
}

.slider-navigation-right {
    margin-left: 10px;
}

.list-group-horizontal {
    display: flex;
    align-items: center; 
}

.slider-container .btn-outline-success {
    margin-top: 9px;
    height: 40px; 
    display: flex;
    align-items: center;
    justify-content: center; 
}
